import React from "react";
import { Link } from "react-router-dom";
import "./NewSidebar.css"

const SubmenuItem = ({
  icon,
  text,
  to,
  className = "",
  isSidebarOpen = true,
  onClick = null
}) => {
  return (
    <li className={`sidebar-menu-title menu-item ${className}`}>
      <Link
        to={to}
        className="d-flex align-items-center link-style"
        onClick={onClick}
      >
        <div className="icon-wrapper">
          <img className="icon change_color" src={icon} alt={text} />
        </div>
        {isSidebarOpen && <span>{text}</span>}
      </Link>
    </li>
  );
};

export default SubmenuItem;
