import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getEmployees } from '../../actions/userActions/employeeAction'
import './Employee.css'
import Loader from '../subComponents/Loader'
import { Link, useHistory } from 'react-router-dom'
import Pagination from '../paginatioin/pagination'
import { Tooltip } from "@mui/material";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import IconButton from '@mui/material/IconButton';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { SIZE_OF_PAGE } from '../../actions/types'
import HeaderComponent from '../reuse_components/HeaderComponent'


function Employees() {
  const dispatch = useDispatch()
  const history = useHistory()
  const [query, setQuery] = useState('')
  const [previousQuery, setPreviousQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  // const [perPage] = useState(10)
  const [permission, setPermission] = useState(false);

  useEffect(() => {
    const pageNumber = currentPage;

    if (query !== previousQuery) {
      setCurrentPage(1);
    }
    dispatch(getEmployees(pageNumber, SIZE_OF_PAGE, query))
    setPreviousQuery(query);
    setButtonPermission()
  }, [currentPage, query])

  const employees = useSelector((state) => state.employees_reducer.employees);
  const totalPages = employees.totalPages;
  const permissions = JSON.parse(localStorage.getItem('permissionsList'))

  // const handlePageChange = (event, value) => {
  //   setCurrentPage(value);
  // };
  const handleSearchChange = (query) => {
    setQuery(query);
    setCurrentPage(1);
    history.push(`?page=1&search=${query}`);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
    history.push(`?page=${page}&search=${query}`);
  };
  const handleAddEmployeeClick = () => {
    history.push('/admin/Addemployees');
  };
  const setButtonPermission = () => {
    if (permissions.includes('CanAddEmployee')) {
      setPermission(true);
    }
  }
  return (
    <>
      <div>
        <HeaderComponent onButtonClick={handleAddEmployeeClick} onSearchChange={handleSearchChange} HeaderTitle='Employees' visibleButton={permission} ButtonTitle="+ Add Employee" />
        <div className="row">
          <table className="table table-striped  mt-4">
            <thead className="text-left" style={{ backgroundColor: "#f5f9fc" }}>
              <tr>
                <td>
                  {" "}
                  <b className="heading_role">Id</b>{" "}
                </td>
                <td>
                  {" "}
                  <b className="heading_role">Name</b>{" "}
                </td>
                <td>
                  {" "}
                  <b className="heading_role">Designation</b>{" "}
                </td>
                <td>
                  {" "}
                  <b className="heading_role">Reports To</b>{" "}
                </td>
                <td>
                  {" "}
                  <b className="heading_role">Status</b>{" "}
                </td>
                <td>
                  {" "}
                  <b className="heading_role">Action</b>{" "}
                </td>
              </tr>
            </thead>
            <tbody className="text-left" style={{ backgroundColor: "#FFFFFF00 !important" }}>
              {employees !== "isLoading" && employees.result != undefined &&
                employees.result.length == 0 && (
                  <tr className="text-center" style={{
                    backgroundColor: "transparent",
                    "--x-table-accent-bg": "transparent",
                  }}>
                    <td colSpan={6}>No data found</td>
                  </tr>
                )}
              {employees === "isLoading" && (
                <tr style={{
                  backgroundColor: "transparent",
                  "--x-table-accent-bg": "transparent",
                }}>
                  <td
                    colSpan={6}
                  >
                    <Loader colored={true} />
                  </td>
                </tr>
              )}
              {employees.result != undefined &&
                employees !== "isLoading" &&
                employees.result.length !== 0 &&
                employees.result.map((row, i) => (
                  <tr
                    key={i}
                    style={{
                      backgroundColor: "transparent",
                      verticalAlign: 'middle',
                      "--x-table-accent-bg": "transparent",
                    }}
                  >
                    <td style={{ verticalAlign: "middle" }}><p className='mt-3'>{row.empId ? row.empId : "N/A"}</p></td>
                    <td style={{ verticalAlign: "middle" }}>
                      <div className="avatar-wrapper">
                        <div className='avatar-set'>
                          <p>
                            {row.firstName.charAt(0)}
                            {row.lastName.charAt(0)}
                          </p>
                        </div>
                        {row.firstName + " "}{row.lastName}
                      </div></td>
                    <td style={{ verticalAlign: "middle" }}><p className='mt-3'>{row.designationName}</p></td>
                    <td style={{ verticalAlign: "middle" }}><p className='mt-3'>{row.reportToName}</p></td>
                    <td style={{ verticalAlign: "middle" }}>
                      <span className='mt-3'
                        style={{
                          color: row.status
                            ? "#2196F3"
                            : "var(--text-primary, rgba(0, 0, 0, 0.87))",
                          borderRadius: "100px",
                          border: `1px solid ${row.status
                            ? "var(--primary-main, #2196F3)"
                            : "var(--chip-defaultEnabledBorder, #BDBDBD)"
                            }`,
                          padding: "3px 10px",
                        }}
                      >
                        {row.status !== undefined ? (row.status ? "Active" : "In-active") : "Active"}
                      </span>

                    </td>


                    <td style={{ verticalAlign: "middle" }}>
                      <div className='mt-3' style={{ display: "flex", gap: "15px" }}>
                        <Tooltip title="View Detail" arrow enterDelay={500} placement='bottom'>

                          <Link
                            to={{
                              pathname: "/admin/ViewEmployees",
                              state: { rowData: row },
                            }}
                          >
                            <IconButton>
                              <RemoveRedEyeOutlinedIcon />
                            </IconButton>
                          </Link>
                        </Tooltip>
                        <Tooltip title="Edit Employee" arrow enterDelay={500} placement="bottom">
                          <Link
                            to={{
                              pathname: '/admin/UpdateEmployees',
                              employee: { row },
                              id: row.id,
                            }}
                          >
                            <IconButton>
                              <EditOutlinedIcon
                              />
                            </IconButton>
                          </Link>
                        </Tooltip>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className='task-pagination-wrapper'>
          <Pagination
            total={totalPages}
            current={currentPage}
            pageSize={1}
            onChange={handlePageChange}
          />
        </div>
      </div>
    </>
  )
}

export default Employees
