import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getPermissions } from "../../actions/userActions/permissionsAction";
import $ from "jquery";
import "./NewSidebar.css";
import { Link } from "react-router-dom";
import {
    TaskExist,
    ManageExist,
    BillingExist,
    InvoiceExist,
} from "./menuArrays";
import { useLocation } from "react-router-dom";
import { logout } from "../../actions/auth/auth";
import SidebarIcons from "./SidebarIcons";
import { DOWNLOAD_APP_URL } from "../../Constatns";
import MenuItem from "./MenuItem";
import SubmenuItem from "./SubmenuItem";

function NewSidebar() {
    const dispatch = useDispatch();
    const location = useLocation();
    const user = JSON.parse(localStorage.getItem("user"));
    const [localPermissions, setLocalPermissions] = useState([]);
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [isTaskDrawerOpen, setIsTaskDrawerOpen] = useState(false);
    const [isManageDrawerOpen, setIsManageDrawerOpen] = useState(false);

    const drawerRef = useRef(null); // Reference to the drawer
    const employeeId =
        user?.result[0] !== undefined ? user.result[0].user.id : false;
    const download_app = DOWNLOAD_APP_URL;

    useEffect(() => {
        !employeeId && dispatch(logout());

        $("body").on("click", "#close-sidebar", function () {
            let $pageWrapper = $(".page-wrapper");
            let $sidebarWrapper = $(".sidebar-wrapper");
            let $sidebarIcon = $(".sidebar-menu-title");

            if ($pageWrapper.hasClass("toggled")) {
                $pageWrapper.removeClass("toggled");
                $sidebarWrapper.removeClass("toggled-sideBar");
                $("body").removeClass("sidebar-open");
                setIsSidebarOpen(false);
            } else {
                $pageWrapper.addClass("toggled");
                $sidebarWrapper.addClass("toggled-sideBar");
                $("body").addClass("sidebar-open");
                setIsSidebarOpen(true);
            }
        });

        $("body").on("click", ".subitem", function () {
            let $pageWrapper = $(".page-wrapper");
            let $sidebarWrapper = $(".sidebar-wrapper");
            let $sidebarIcon = $(".sidebar-menu-title");

            if ($pageWrapper.hasClass("toggled")) {
                $pageWrapper.addClass("subitemclicked");
                $pageWrapper.removeClass("toggled");
                $("body").removeClass("sidebar-open");
                setIsSidebarOpen(false);
            }
        });


        $("body").on("click", ".item", function () {
            let $pageWrapper = $(".page-wrapper");
            let $sidebarWrapper = $(".sidebar-wrapper");
            let $sidebarIcon = $(".sidebar-menu-title");

            if ($pageWrapper.hasClass("subitemclicked")) {
                $pageWrapper.removeClass("subitemclicked");
                $pageWrapper.addClass("toggled");
                $("body").addClass("sidebar-open");
                setIsSidebarOpen(true);
            }
        });

    }, []);

    useEffect(() => {
        const permissionsListString = localStorage.getItem("permissionsList");
        if (permissionsListString != null) {
            setLocalPermissions(JSON.parse(permissionsListString));
        }
    }, []);

    const permissions = useSelector(
        (state) => state.permissions_reducer.permissions
    );

    const handleDrawerListItemClick = () => {
        setIsSidebarOpen(false);
    };

    const handleManageItemClick = () => {
        setIsTaskDrawerOpen(false);
        setIsManageDrawerOpen(true);
    };
    const handleTaskItemClick = () => {
        setIsManageDrawerOpen(false);
        setIsTaskDrawerOpen(true);
    };

    const handleCloseDrawer = () => {
        setIsTaskDrawerOpen(false);
        setIsManageDrawerOpen(false);
        setIsSidebarOpen(true);
    };
    const path = (path) => {
        if (location.pathname === path) {
            return "active";
        }
    };
    const logoutHandle = async () => {
        const user = JSON.parse(localStorage.getItem("user"));
        if (user && user?.result)
            dispatch(logout(user.result[0]?.user.id, ""));
    };
    const pathactive = (pathArray) => {
        if (pathArray.includes(location.pathname)) {
            return "active";
        }
        return ""; // Return an empty string if no match
    };

    const taskurl = ["/admin/tasks", "/admin/AddTasks", "/admin/locate", "/TaskTarget", "/admin/completedtask"];
    const manageUrl = [
        "/admin/employees", "/admin/Addemployees", "/admin/UpdateEmployees", "/admin/ViewEmployees",
        "/admin/permissions", "/admin/ViewPermissions", "/admin/AddPermissions", "/admin/UpdatePermissions",
        "/admin/officeLocations", "/admin/UpdateOfficeLocations", "/admin/AddOfficeLocations",
        "/admin/teams", "/admin/UpdateTeams", "/admin/AddTeams",
        "/admin/products", "/admin/UpdateProducts", "/admin/AddProducts",
        "/admin/designations", "/admin/UpdateDesignations", "/admin/AddDesignations",
        "/admin/departments", "/admin/UpdateDepartments", "/admin/AddDepartments",
        "/admin/doctors", "/admin/UpdateDoctors", "/admin/AddDoctors",
        "/admin/doctorRanks", "/admin/UpdateDoctorRanks", "/admin/AddDoctorRanks",
        "/admin/doctorSpecializations", "/admin/UpdateDoctorSpecializations", "/admin/AddDoctorSpecializations",
        "/admin/practiceLocations", "/admin/UpdatePracticeLocations", "/admin/AddPracticeLocations",
        "/admin/locationTypes", "/admin/UpdateLocationTypes", "/admin/AddLocationTypes",
        "/admin/employmentStatuses", "/admin/UpdateEmploymentStatuses", "/admin/AddEmploymentStatuses",
        "/admin/CustomerRank", "/admin/UpdateCustomerRank", "/admin/AddCustomerRank",
        "/admin/subTasks", "/admin/UpdateSubTasks", "/admin/AddSubTasks",
        "/admin/taskStatuses", "/admin/taskTypes", "/admin/taskPriorities",
        "/admin/cities", "/admin/UpdateCities", "/admin/AddCities",
        "/admin/countries", "/admin/roles", "/admin/UpdateRoles", "/admin/AddRoles"
    ];
    return (
        <>
            <nav
                id="sidebar"
                className="sidebar-wrapper sidebar-border-class sidebar-shadow"

            >
                <div className="sidebar-content"
                    style={{ position: "relative", overflow: "visible" }}
                >
                    <div className="sidebar-menu">
                        <ul className="sidebar-menu-list">
                            <li className={`align-items-center gap-3 ${path("/admin")}`}>
                                <div className="align-items-center">
                                    <div id="close-sidebar" style={{ paddingTop: "24px", position: "absolute", top: "-16px", right: "-18px", zIndex: "999" }}>
                                        <i
                                            style={{
                                                paddingLeft: "15px",
                                                paddingTop: "24px",
                                                cursor: "pointer",
                                            }}
                                        >
                                            <img
                                                src={
                                                    isSidebarOpen
                                                        ? SidebarIcons.SidebarIcon
                                                        : SidebarIcons.CloseSidebar
                                                }
                                            />
                                        </i>
                                    </div>

                                </div>
                            </li>
                            <li
                                className={`dashboard-dropdown task-dropdown ${isSidebarOpen ? "" : "no-arrow"
                                    }`}
                                onClick={handleCloseDrawer}
                            >
                                <MenuItem
                                    icon={SidebarIcons.dashboardIcon}
                                    text="Dashboard"
                                    to="/admin"
                                    className={path("/admin") + " item"}
                                    isSidebarOpen={isSidebarOpen}
                                    hasArrow={false}
                                />
                            </li>

                            {TaskExist(permissions, localPermissions) && (
                                <li
                                    className={`dashboard-dropdown task-dropdown ${isSidebarOpen ? "" : "no-arrow"
                                        }`}
                                >
                                    <MenuItem
                                        icon={SidebarIcons.taskIcon}
                                        text="Task"
                                        onClick={handleTaskItemClick}
                                        className={`sidebar-dropdown ${pathactive(taskurl)} item`}
                                        isSidebarOpen={isSidebarOpen}
                                        hasArrow={true}
                                        to="#"
                                    />
                                </li>
                            )}

                            {ManageExist(permissions, localPermissions) && (
                                <li
                                    className={`dashboard-dropdown task-dropdown ${isSidebarOpen ? "" : "no-arrow"
                                        }`}
                                >
                                    <MenuItem
                                        icon={SidebarIcons.manageIcon}
                                        text="Manage"
                                        onClick={handleManageItemClick}
                                        className={`sidebar-dropdown ${pathactive(manageUrl)} item`}
                                        isSidebarOpen={isSidebarOpen}
                                    />
                                </li>
                            )}

                            {/* Forms Start  */}
                            <li
                                className={`dashboard-dropdown task-dropdown ${isSidebarOpen ? "" : "no-arrow"
                                    }`}
                                onClick={handleCloseDrawer}
                            >
                                <MenuItem
                                    icon={SidebarIcons.Forms_icon}
                                    text="Forms"
                                    to="/admin/forms"
                                    className={path("/admin/forms") + " item"}
                                    isSidebarOpen={isSidebarOpen}
                                    hasArrow={false}
                                />
                            </li>
                            {/* form section End  */}
                        </ul>
                        <ul className="logout-menu pr-3">
                            {isSidebarOpen && (
                                <div className="download_app">
                                    <button onClick={() => (window.location.href = download_app)}>
                                        <img
                                            src={SidebarIcons.download_app}
                                            width={30}
                                            height={20}
                                            alt=""
                                        />
                                        Download Android App
                                    </button>
                                </div>
                            )}
                            <hr></hr>
                            <li className="logout-item py-2 pr-2"
                                onClick={logoutHandle}>
                                <MenuItem
                                    icon={SidebarIcons.logoutIcon}
                                    text="Logout"
                                    className="logout-link"
                                    isSidebarOpen={isSidebarOpen}
                                    hasArrow={false}
                                    to="/logout"
                                />
                            </li>
                        </ul>
                    </div>

                </div>
            </nav>

            {isTaskDrawerOpen && (
                <div
                    className="task-drawer task-drawer-wrapper"
                    ref={drawerRef} // Reference the drawer element
                    style={{
                        width: "250px",
                        position: "absolute",
                        left: isSidebarOpen ? "265px" : "75px",
                        padding: "10px",
                    }}
                >
                    <div className="drawer-heading">
                        Task
                    </div>
                    <ul className="task-ul task-dropdown">
                        {(permissions.includes("CanViewTask") || localPermissions.includes("CanViewTask")) && (
                            <SubmenuItem
                                icon={SidebarIcons.taskQueueIcon}
                                text="Task List"
                                to="/admin/tasks"
                                className={`${path("/admin/tasks") === "active" || path("/admin/AddTasks") === "active" ? "active" : ""} subitem`}
                                onClick={handleDrawerListItemClick}
                            />
                        )}

                        {(permissions.includes("CanLocateSubOrdinate") || localPermissions.includes("CanLocateSubOrdinate")) && (
                            <SubmenuItem

                                icon={SidebarIcons.locateIcon}
                                text="Locate"
                                to="/admin/locate"
                                className={path("/admin/locate") + " subitem"}
                                onClick={handleDrawerListItemClick}
                            />
                        )}

                        {(permissions.includes("CanViewTaskTarget") || localPermissions.includes("CanViewTaskTarget")) && (
                            <SubmenuItem
                                icon={SidebarIcons.taskTargetIcon}
                                text="Task Target"
                                to="/admin/TaskTarget"
                                className={path("/admin/TaskTarget") + " subitem"}
                                onClick={handleDrawerListItemClick}
                            />
                        )}

                        {(permissions.includes("CanViewArchivedTask") || localPermissions.includes("CanViewArchivedTask")) && (
                            <SubmenuItem
                                icon={SidebarIcons.taskTargetIcon}
                                text="Archive Task"
                                to="/admin/completedtask"
                                className={path("/admin/completedtask") + " subitem"}
                                onClick={handleDrawerListItemClick}
                            />
                        )}
                    </ul>
                </div>
            )}

            {isManageDrawerOpen && (
                <div
                    className="manage-drawer manage-drawer-wrapper"
                    ref={drawerRef} // Reference the drawer element
                    style={{
                        width: "250px",
                        position: "absolute",
                        left: isSidebarOpen ? "270px" : "75px",
                        padding: "10px",
                        margin: 0,
                        overflowY: "scroll",
                        overflowX: "hidden",
                        scrollbarWidth: "none",
                        msOverflowStyle: "none",
                    }}
                >
                    <div className="drawer-heading">
                        Manage
                    </div>
                    <ul className="manage-dropdown">
                        {(permissions.includes("CanViewEmployee") ||
                            localPermissions.includes("CanViewEmployee")) && (
                                <SubmenuItem
                                    icon={SidebarIcons.employeesIcon}
                                    text="Employee"
                                    to="/admin/employees"
                                    className={`${path("/admin/employees") === "active" || path("/admin/Addemployees") === "active" || path("/admin/UpdateEmployees") === "active" || path("/admin/ViewEmployees") === "active" ? "active" : ""} subitem`}
                                    onClick={handleDrawerListItemClick}
                                />

                            )}
                        {(permissions.includes("CanViewPermission") ||
                            localPermissions.includes("CanViewPermission")) && (
                                <SubmenuItem
                                    icon={SidebarIcons.permissionIcon}
                                    text="Permission Group"
                                    to="/admin/permissions"
                                    className={`${path("/admin/permissions") === "active" ||
                                        path("/admin/ViewPermissions") === "active" ||
                                        path("/admin/AddPermissions") === "active" ||
                                        path("/admin/UpdatePermissions") === "active"
                                        ? "active" : ""} subitem`}
                                    onClick={handleDrawerListItemClick}
                                />
                            )}
                        {(permissions.includes("CanViewOfficeLocation") ||
                            localPermissions.includes("CanViewOfficeLocation")) && (
                                <SubmenuItem
                                    icon={SidebarIcons.officeLocationIcon}
                                    text="Office Location"
                                    to="/admin/officeLocations"
                                    className={`${path("/admin/officeLocations") === "active" ||
                                        path("/admin/UpdateOfficeLocations") === "active" ||
                                        path("/admin/AddOfficeLocations") === "active"
                                        ? "active" : ""} subitem`}
                                    onClick={handleDrawerListItemClick}
                                />
                            )}
                        {(permissions.includes("CanViewTeam") ||
                            localPermissions.includes("CanViewTeam")) && (
                                <SubmenuItem
                                    icon={SidebarIcons.teamIcon}
                                    text="Team"
                                    to="/admin/teams"
                                    className={`${path("/admin/teams") === "active" ||
                                        path("/admin/UpdateTeams") === "active" ||
                                        path("/admin/AddTeams") === "active"
                                        ? "active" : ""} subitem`}

                                    onClick={handleDrawerListItemClick}
                                />
                            )}
                        {(permissions.includes("CanViewProduct") ||
                            localPermissions.includes("CanViewProduct")) && (
                                <SubmenuItem
                                    icon={SidebarIcons.productIcon}
                                    text="Product"
                                    to="/admin/products"
                                    className={`${path("/admin/products") === "active" ||
                                        path("/admin/UpdateProducts") === "active" ||
                                        path("/admin/AddProducts") === "active"
                                        ? "active" : ""} subitem`}
                                    onClick={handleDrawerListItemClick}
                                />
                            )}
                        {(permissions.includes("CanViewDesignation") ||
                            localPermissions.includes("CanViewDesignation")) && (
                                <SubmenuItem
                                    icon={SidebarIcons.designationIcon}
                                    text="Designation"
                                    to="/admin/designations"
                                    className={`${path("/admin/designations") === "active" ||
                                        path("/admin/UpdateDesignations") === "active" ||
                                        path("/admin/AddDesignations") === "active"
                                        ? "active" : ""} subitem`}
                                    onClick={handleDrawerListItemClick}
                                />
                            )}
                        {(permissions.includes("CanViewDepartment") ||
                            localPermissions.includes("CanViewDepartment")) && (
                                <SubmenuItem
                                    icon={SidebarIcons.departmentIcon}
                                    text="Department"
                                    to="/admin/departments"
                                    className={`${path("/admin/departments") === "active" ||
                                        path("/admin/UpdateDepartments") === "active" ||
                                        path("/admin/AddDepartments") === "active"
                                        ? "active" : ""} subitem`}
                                    onClick={handleDrawerListItemClick}
                                />
                            )}
                        {(permissions.includes("CanViewDoctor") ||
                            localPermissions.includes("CanViewDoctor")) && (
                                <SubmenuItem
                                    icon={SidebarIcons.doctorIcon}
                                    text="Doctor"
                                    to="/admin/doctors"
                                    className={`${path("/admin/doctors") === "active" ||
                                        path("/admin/UpdateDoctors") === "active" ||
                                        path("/admin/AddDoctors") === "active"
                                        ? "active" : ""} subitem`}
                                    onClick={handleDrawerListItemClick}
                                />
                            )}
                        {(permissions.includes("CanViewDoctorRank") ||
                            localPermissions.includes("CanViewDoctorRank")) && (
                                <SubmenuItem
                                    icon={SidebarIcons.doctorRankIcon}
                                    text="Doctor Designation"
                                    to="/admin/doctorRanks"
                                    className={`${path("/admin/doctorRanks") === "active" ||
                                        path("/admin/UpdateDoctorRanks") === "active" ||
                                        path("/admin/AddDoctorRanks") === "active"
                                        ? "active" : ""} subitem`}
                                    onClick={handleDrawerListItemClick}
                                />
                            )}
                        {(permissions.includes("CanViewDoctorSpecialization") ||
                            localPermissions.includes(
                                "CanViewDoctorSpecialization"
                            )) && (
                                <SubmenuItem
                                    icon={SidebarIcons.doctorSpecializationIcon}
                                    text="Doctor Specialization"
                                    to="/admin/doctorSpecializations"
                                    className={`${path("/admin/doctorSpecializations") === "active" ||
                                        path("/admin/UpdateDoctorSpecializations") === "active" ||
                                        path("/admin/AddDoctorSpecializations") === "active"
                                        ? "active" : ""} subitem`}
                                    onClick={handleDrawerListItemClick}
                                />
                            )}
                        {(permissions.includes("CanViewPracticeLocation") ||
                            localPermissions.includes(
                                "CanViewPracticeLocation"
                            )) && (
                                <SubmenuItem
                                    icon={SidebarIcons.practiceLocationIcon}
                                    text="Practice Location"
                                    to="/admin/practiceLocations"
                                    className={`${path("/admin/practiceLocations") === "active" ||
                                        path("/admin/UpdatePracticeLocations") === "active" ||
                                        path("/admin/AddPracticeLocations") === "active"
                                        ? "active" : ""} subitem`}
                                    onClick={handleDrawerListItemClick}
                                />
                            )}
                        {(permissions.includes("CanViewPracticeLocation") ||
                            localPermissions.includes(
                                "CanViewPracticeLocation"
                            )) && (
                                <SubmenuItem
                                    icon={SidebarIcons.practiceLocationTypeIcon}
                                    text="Practice Location Type"
                                    to="/admin/locationTypes"
                                    className={`${path("/admin/locationTypes") === "active" ||
                                        path("/admin/UpdateLocationTypes") === "active" ||
                                        path("/admin/AddLocationTypes") === "active"
                                        ? "active" : ""} subitem`}
                                    onClick={handleDrawerListItemClick}
                                />
                            )}
                        {(permissions.includes("CanViewEmploymentStatus") ||
                            localPermissions.includes(
                                "CanViewEmploymentStatus"
                            )) && (
                                <SubmenuItem
                                    icon={SidebarIcons.employeeStatusIcon}
                                    text="Employment Status"
                                    to="/admin/employmentStatuses"
                                    className={`${path("/admin/employmentStatuses") === "active" ||
                                        path("/admin/UpdateEmploymentStatuses") === "active" ||
                                        path("/admin/AddEmploymentStatuses") === "active"
                                        ? "active" : ""} subitem`}
                                    onClick={handleDrawerListItemClick}
                                />
                            )}

                        {(permissions.includes("CanViewCustomerRank") ||
                            localPermissions.includes("CanViewCustomerRank")) && (
                                <SubmenuItem
                                    icon={SidebarIcons.CustomerRank}
                                    text="Customer Rank"
                                    to="/admin/CustomerRank"
                                    className={`${path("/admin/CustomerRank") === "active" ||
                                        path("/admin/UpdateCustomerRank") === "active" ||
                                        path("/admin/AddCustomerRank") === "active"
                                        ? "active" : ""} subitem`}
                                    onClick={handleDrawerListItemClick}
                                />
                            )}

                        {(permissions.includes("CanViewSubTask") ||
                            localPermissions.includes("CanViewSubTask")) && (
                                <SubmenuItem
                                    icon={SidebarIcons.subTasksIcon}
                                    text="Sub Task"
                                    to="/admin/subTasks"
                                    className={`${path("/admin/subTasks") === "active" ||
                                        path("/admin/UpdateSubTasks") === "active" ||
                                        path("/admin/AddSubTasks") === "active"
                                        ? "active" : ""} subitem`}
                                    onClick={handleDrawerListItemClick}
                                />
                            )}
                        {(permissions.includes("CanViewTaskStatus") ||
                            localPermissions.includes("CanViewTaskStatus")) && (
                                <SubmenuItem
                                    icon={SidebarIcons.taskStatusIcon}
                                    text="Task Status"
                                    to="/admin/taskStatuses"
                                    className={path("/admin/taskStatuses") + " subitem"}
                                    onClick={handleDrawerListItemClick}
                                />
                            )}
                        {(permissions.includes("CanViewTaskType") ||
                            localPermissions.includes("CanViewTaskType")) && (
                                <SubmenuItem
                                    icon={SidebarIcons.taskTypeIcon}
                                    text="Task Type"
                                    to="/admin/taskTypes"
                                    className={path("/admin/taskTypes") + " subitem"}
                                    onClick={handleDrawerListItemClick}
                                />
                            )}
                        {(permissions.includes("CanViewTaskPriority") ||
                            localPermissions.includes("CanViewTaskPriority")) && (
                                <SubmenuItem
                                    icon={SidebarIcons.taskPriorityIcon}
                                    text="Task Priority"
                                    to="/admin/taskPriorities"
                                    className={`${path("/admin/taskPriorities") === "active" ||
                                        path("/admin/UpdateTaskPriorities") === "active" ||
                                        path("/admin/AddTaskPriorities") === "active"
                                        ? "active" : ""} subitem`}
                                    onClick={handleDrawerListItemClick}
                                />
                            )}

                        {(permissions.includes("CanViewCity") ||
                            localPermissions.includes("CanViewCity")) && (
                                <SubmenuItem
                                    icon={SidebarIcons.cityIcon}
                                    text="City"
                                    to="/admin/cities"
                                    className={`${path("/admin/cities") === "active" ||
                                        path("/admin/UpdateCities") === "active" ||
                                        path("/admin/AddCities") === "active"
                                        ? "active" : ""} subitem`}
                                    onClick={handleDrawerListItemClick}
                                />
                            )}
                        {(permissions.includes("CanViewCountry") ||
                            localPermissions.includes("CanViewCountry")) && (
                                <SubmenuItem
                                    icon={SidebarIcons.countryIcon}
                                    text="Country"
                                    to="/admin/countries"
                                    className={path("/admin/countries") + " subitem"}
                                    onClick={handleDrawerListItemClick}
                                />
                            )}
                        {(permissions.includes("CanViewRole") ||
                            localPermissions.includes("CanViewRole")) && (
                                <SubmenuItem
                                    icon={SidebarIcons.rolesIcon}
                                    text="Role"
                                    to="/admin/roles"
                                    className={`${path("/admin/roles") === "active" ||
                                        path("/admin/UpdateRoles") === "active" ||
                                        path("/admin/AddRoles") === "active"
                                        ? "active" : ""} subitem`}
                                    onClick={handleDrawerListItemClick}
                                />
                            )}
                    </ul>
                </div>
            )}
        </>
    );
}

export default NewSidebar;
